import React from "react";
import { Row, Col, Hidden, Visible } from "react-grid-system";
import { Modal, Button, Input, carousel } from 'antd';
import { observer } from "mobx-react";
import Carousel from 'react-multi-carousel';
import { ArrowLeftOutlined, ArrowRightOutlined, CloseOutlined } from '@ant-design/icons';
import { Container, CustomFooter } from "components";
import CustomSeamless from "components/cumstomSeamless";
import banner from "assets/home/banner.png";
import problemCurl from "assets/home/problem-curl.svg";
import accessibleCurl from "assets/home/accessibleCurl.svg";
import liveClass from "assets/home/liveClass.svg";
import handRaise from "assets/home/handRaise.svg";
import whiteBoard from "assets/home/whiteBoard.svg";
import touchTalk from "assets/home/touchTalk.svg";
import assignmentTest from "assets/home/assignmentTest.svg";
import paymentGateway from "assets/home/paymentGateway.svg";
import chatBox from "assets/home/chatBox.svg";
import digitalLibrary from "assets/home/digitalLibrary.svg";
import firstOfKind from "assets/home/firstOfKind.svg";
import youInterested from "assets/home/youInterested.png";
import youInterestedMobile from "assets/home/youInterestedMobile.png";
import homeVideo from "./homeVideo.mp4";
import homeVideo_mobile from "./homeVideo_mobile.mp4";
import Store from "./store";
import ServiceStore from "services/store";
import RequestModal from "./requestModal";
import './styles.css';
import image_11 from "assets/home/image_11.svg"
import HomeStore from "pages/home/store";
import Mask_group from "assets/home/Mask_group.svg"
import Path_52834 from "assets/home/Path_52834.svg"
import Path_617251 from "assets/home/Path_617251.svg";
import Group_595611 from "assets/home/Group _595611.svg"
import Vector_right from "assets/home/Vector _right.svg"
import flowerPot from "assets/home/flowerPot.svg"
import Group_5946 from "assets/home/Group_5946.svg"
import ic_user1 from "assets/home/ic_user1.svg"
import ic_live_1 from "assets/home/ic_live_1.svg"
import Group1 from "assets/home/Group1.svg"
import Group2 from "assets/home/Group2.svg"
import Group3 from "assets/home/Group3.svg"
import icuser1 from "assets/home/icuser1.svg"
import ic_schedule from "assets/home/ic_schedule.svg"
import ic_classrooms1 from "assets/home/ic_classrooms1.svg"
import ic_fee1 from "assets/home/ic-fee1.svg"
import Group_15907 from "assets/home/Group_15907.svg"
import Group_59113 from "assets/home/Group_59113.svg"
import Group_5912_1_1 from "assets/home/Group_5912_1_1.svg"
import Rectangle_3699 from "assets/home/Rectangle_3699.svg"
import Vector1_2 from "assets/home/Vector1_2.svg"
import Vector1_1 from "assets/home/Vector1_1.svg"
import Ellipse_8 from "assets/home/Ellipse_8.svg"
import Rankers_Point from "assets/home/Rankers_Point.svg"
import bench_image from "assets/home/bench_image.svg"
import bench_image1 from "assets/home/bench_image1.svg"
import bench_image2 from "assets/home/bench_image2.svg"
import flowerpot3 from "assets/home/flowerpot3.svg"
import flowerpot1 from "assets/home/flowerpot1.svg"
import annanmuel from "assets/home/annanmuel.svg"
import book1 from "assets/home/book1.svg"
import briliant from "assets/home/briliant.svg"
import deg from "assets/home/deg.svg"
import flora from "assets/home/flora.svg"
import goreclasses from "assets/home/goreclasses.svg"
import rankerspoint from "assets/home/rankerspoint.svg"
import scc from "assets/home/scc.svg"
import teachlang from "assets/home/teachlang.svg"
import writinghand from "assets/home/writinghand.svg"
import OurLogData from "components/ourLogData";
import Marquee from "react-fast-marquee";
import flwer11 from "assets/home/flwer11.svg"
import s1 from "assets/home/s1.svg"
import s2 from "assets/home/s2.svg"
import s3 from "assets/home/s3.svg"
import s4 from "assets/home/s4.svg"
import s5 from "assets/home/s5.svg"
import s6 from "assets/home/s6.svg"
import s7 from "assets/home/s7.svg"
import s8 from "assets/home/s8.svg"
import s9 from "assets/home/s9.svg"
import schedule1 from "assets/home/schedule1.svg"
import s10 from "assets/home/s10.svg"
import { Footer } from "antd/lib/layout/layout";
// import '../pages/about/styles.css';


// const accessible_subtitle = 'We provide customizable features that will make your online learning/teaching experience a near physical classroom experience.';
// const live_class = `It’s equipped, interactive and seamless. It’s a physical classroom inside your mobile phone.`;
// const hand_raise = 'Raise your hand and the classroom is yours to address in company of your teacher.';
// const white_board = `You can't call it a classroom if it doesn't have a board right? This one's a lot smarter than any you have seen before.`;
// const touch_talk = `Ever used a walkie-talkie? That’s what this is.`;
// const chat_box = 'Avoid noise in the class room by using the chat box for interaction with your classmates.';
// const digital_library = 'A complete repository - a one stop solution for all study material needs.';
// const first_kind_text = `An online platform that can truly be called a 'Classroom'. This application will take teachers and students back inside classrooms and digitally empower learning as you know it, but from the safety and comfort of your homes.`;
// const test_text = `You wouldn’t believe that assignment & test creation, submission & evaluation could be done so easily.`;
// const payment_gateway = `Fee management of an entire institute in the palm of your hand.`;

const discover = `DISCOVER A NEW WAY OF LEARNING`
const embrace1 = `Wanna register on aulas? It's real quick and simple. Let us show you how!`
const discover1 = `Your entire institute in the palm of your hand.`
const onlineDef = `The Covid-19 pandemic has made one thing abundantly clear – technology is the only answer to all our current mi...`
const reasonDef = `Online education, even in the current era, faces a certain level of backlash. For many, it is still a debatable subject whe...`
const adaptingDef = `The past teaches us the lessons that strengthen our future. COVID-19 turned out to be an unexpected lesson. ...`


const Row5 = ({ image, title, subtitle }) => (
  <div className='m-b-50'>
    <img src={image} alt="" />
    <div className='title-500 color-black m-b-10 m-t-20 font-600'>{title}</div>
    <div className='subtitle-500 color-grey'>{subtitle}</div>
  </div>
)

const contentStyle = {
  height: '160px',
  BackgroundColor: 'red',
  lineHeight: '160px',
  textAlign: 'center',
  background: '#364d79',
};

const Row1 = ({ image1, image, image2, title, subtitle, index, newTitle, image9, image8 }) => (
  <Row className='m-t-200'>
    <Col xs={12} sm={6} md={5} className='c-jc'>
      <div style={{ textAlign: "justify", fontWeight: "bold" }} className='subtitle-500 color-grey'>{newTitle}
        <img src={image1} alt="" style={{ width: "8%", marginBottom: "60px", marginLeft: "12px" }} />
      </div>
      <div style={{ marginBottom: "-10px", marginTop: "-30px" }}>
        <img src={image2} alt="" style={{ width: "6%" }} />
      </div>
      <img src={image9} alt="" style={{ width: "8%", marginBottom: "20px", }} />
      <div className='title-800 color-black m-b-20' style={{width: 600}}>{title}
        <img src={image8} alt="" style={{ width: "6%", marginLeft: "200px" }} />
      </div>
      <div style={{ textAlign: "justify", }} className='subtitle-500 color-grey'>{subtitle}</div>
    </Col>
    <Col xs={12} sm={6} md={7} className='m-b-20 r-fe-c'>
      <img src={image} alt="" style={{ width: '80%' }} />
    </Col>
  </Row>
)
const Row2 = ({ image1, image, image2, title, subtitle, index, newTitle, image9, image8 }) => (
  <Row>
    <Col xs={12} sm={6} md={7} >
      <center>
        <img src={image} alt="" style={{ textAlign: "center", width: '80%' }} />
      </center>
    </Col>
    <Col xs={12} sm={6} md={5} className='c-jc'>
      <div style={{ textAlign: "justify", fontWeight: "bold" }} className='subtitle-500 color-grey'>{newTitle}
        <img src={image1} alt="" style={{ width: "8%", marginBottom: "60px", marginLeft: "12px" }} />
      </div>
      <div style={{ marginBottom: "-10px", marginTop: "-30px" }}>
        <img src={image2} alt="" style={{ width: "6%" }} />
      </div>
      <img src={image9} alt="" style={{ width: "8%", marginBottom: "10px", }} />
      <div className='title-700 color-black' style={{ fontSize: "2.06rem" }}>{title}
        <img src={image8} alt="" style={{ width: "6%", marginLeft: "200px" }} />
      </div>
      <div style={{ textAlign: "justify", }} className='subtitle-500 color-grey'>{subtitle}</div>
    </Col>

  </Row>
)
const Row3 = ({ image1, image, image2, image3, }) => (
  <Row className='m-t-50'>
    <Col xs={12} sm={6} md={5} className='c-jc'>
      <div>
        <img src={image3} alt="" style={{ marginLeft: "140px", marginBottom: "60px" }} />
      </div>
      <div style={{ marginTop: "20px" }}>
        <div style={{ display: "flex" }}>
          <img src={image1} alt="" style={{ width: "5%" }} />
          <div style={{ textAlign: "justify", marginLeft: "10px", marginTop: "4px" }} className='subtitle-500 color-grey' >Your name, your face, your logo, your brand</div>
        </div>
        <div style={{ display: "flex", marginTop: "13px" }}>
          <img src={image1} alt="" style={{ width: "5%" }} />
          <div style={{ textAlign: "justify", marginLeft: "10px", marginTop: "4px" }} className='subtitle-500 color-grey' >Available on Android Play Store</div>
        </div>
        <div style={{ display: "flex", marginTop: "13px" }}>
          <img src={image1} alt="" style={{ width: "5%" }} />
          <div style={{ textAlign: "justify", marginLeft: "10px", marginTop: "4px" }} className='subtitle-500 color-grey' >Easy access, user friendly, holistic</div>
        </div>
        <div style={{ display: "flex", marginTop: "13px" }}>
          <img src={image1} alt="" style={{ width: "5%" }} />
          <div style={{ textAlign: "justify", marginLeft: "10px", marginTop: "4px" }} className='subtitle-500 color-grey' ><b>aulas care</b> and <b>aulas Assistant</b> always at your service</div>
        </div>
      </div>
      <div style={{ marginTop: "100px", }}>
        <img src={image2} alt="" style={{ width: "25%", }} />
      </div>
    </Col>
    <Col xs={12} sm={6} md={7} className='m-b-20 r-fe-c'>
      <img src={image} alt="" style={{ width: '80%' }} />
    </Col>
  </Row>
)

const Row10 = ({ image1, image, image2, image3, }) => (
  <Row className='m-t-50'>
    <Col xs={12} sm={6} md={7} className='m-b-20 r-fe-c'>
      <img src={image3} alt="" style={{ marginLeft: "140px", }} />
    </Col>
    <Col xs={12} sm={6} md={5} className='c-jc'>
      <center>
        <img src={image} alt="" style={{ width: '80%' }} />
      </center>
      <div style={{ marginTop: "20px" }}>
        <div style={{ display: "flex" }}>
          <img src={image1} alt="" style={{ width: "5%" }} />
          <div style={{ textAlign: "justify", marginLeft: "10px", marginTop: "4px" }} className='subtitle-500 color-grey' >Your name, your face, your logo, your brand</div>
        </div>
        <div style={{ display: "flex", marginTop: "13px" }}>
          <img src={image1} alt="" style={{ width: "5%" }} />
          <div style={{ textAlign: "justify", marginLeft: "10px", marginTop: "4px" }} className='subtitle-500 color-grey' >Available on Android Play Store</div>
        </div>
        <div style={{ display: "flex", marginTop: "13px" }}>
          <img src={image1} alt="" style={{ width: "5%" }} />
          <div style={{ textAlign: "justify", marginLeft: "10px", marginTop: "4px" }} className='subtitle-500 color-grey' >Easy access, user friendly, holistic</div>
        </div>
        <div style={{ display: "flex", marginTop: "13px" }}>
          <img src={image1} alt="" style={{ width: "5%" }} />
          <div style={{ textAlign: "justify", marginLeft: "10px", marginTop: "4px" }} className='subtitle-500 color-grey' ><b style={{ color: "black" }}>aulas Care</b> and <b style={{ color: "black" }}>aulas Assistant</b> always at your service</div>
        </div>
      </div>
      <div style={{ textAlign: "center" }}>
        <img src={image2} alt="" style={{ width: "25%", }} />
      </div>
    </Col>

  </Row>
)
const Row6 = ({ image, title, subtitle, value }) => (
  <div style={{ contentStyle, marginTop: "20px" }} >
    <img src={image} alt="" style={{ width: "100%" }} />
    <div className='title-500 color-black m-b-10 m-t-20 font-600'>{title}</div>

    <div className='subtitle-500 color-grey'>{subtitle} <b style={{ color: "#0779E4", fontWeight: "bold", cursor: 'pointer' }} onClick={() => window.open(value)}>{"Read full article"}</b></div>
    {/* <div  className='subtitle-500 color-grey'>{"Read full article"}</div> */}


  </div>
)
const Row11 = ({ image, title, subtitle }) => (
  <div style={{ marginTop: "20px" }}>
    <OurLogData />
  </div>
)

const Row4 = ({ image, title, subtitle }) => (
  <div style={{ marginLeft: "30px", marginTop: "30px" }}>
    <Col xs={12} sm={6} md={7} className='m-b-20 r-fe-c'>
      <div className='title-500 color-black m-b-10  font-600' style={{ marginRight: "-50px" }}>{title}</div>
    </Col>
  </div>

)
const Row8 = ({ image, title, subtitle }) => (
  <div>
    <Col xs={12} sm={6} md={5} className='c-jc'>
      <div style={{ textAlign: "center" }}>
        <img src={image} alt="" style={{ width: "20%", width: "30%" }} />
      </div>
    </Col>
    <Col xs={12} sm={6} md={7} className='m-b-20 r-fe-c' >
      <div className='title-500 color-black m-b-10 m-t-20 ' style={{ fontWeight: "bold", fontSize: "1.5rem", marginRight: "50px" }}>{title}</div>
    </Col>

  </div>
)
const Row13 = ({ image1, title, subtitle, }) => (
  <Row className='m-t-100' style={{ marginTop: 150 }}>
    <Col>
      <div className='m-t-50'>
        <img src={image1} alt="" style={{ width: "8%", marginBottom: "60px", marginLeft: "12px" }} />
        <div className='title-500 color-black m-b-10 m-t-20 font-600'>{title}</div>
        <div style={{ textAlign: "justify" }} className='subtitle-500 color-grey'>{subtitle}</div>
      </div>
    </Col>
    <Col xs={12} sm={6} md={5} className='c-jc'>
      <div style={{ marginRight: "-100px" }}>
        <video width="100%" height="100%" loop={true} autoPlay={true} playsInline={true} muted={true}>
          <source src={homeVideo} type="video/mp4" />
        </video>
      </div>
    </Col>

  </Row>
)
const Row14 = ({ image1, title, subtitle, }) => (
  <Row className='m-t-50'>
    <Col xs={12} sm={6} md={5} className='c-jc'>
      <div>
        <video width="100%" loop={true} autoPlay={true} playsInline={true} muted={true}>
          <source src={homeVideo} type="video/mp4" />
        </video>
      </div>
    </Col>
    <Col>
      <div>
        <img src={image1} alt="" style={{ width: "8%", marginLeft: "12px" }} />
        <div className='title-500 color-black m-b-10 m-t-20 font-600' style={{ fontSize: "1.1rem" }}>{title}</div>
        <div style={{ textAlign: "justify" }} className='subtitle-500 color-grey'>{subtitle}</div>
      </div>
    </Col>
  </Row>
)


// const Row15=({})=>(

//   <div style={{ position: 'absolute', }}>
//               <Marquee speed={60} gradient={false}>
//               <div style={{ width: '100%', marginTop: "10px",position:"relative"}}>
//                 <div style={{ display: "flex", width: "110%"}}>
//                   <div>
//                     <img src={rankerspoint} style={{ width: "80%" }} />
//                   </div>
//                   <div style={{ marginLeft: "20px" }}>
//                     <img src={book1} style={{ width: "80%" }} />
//                   </div>
//                   <div style={{ marginLeft: "20px" }}>
//                     <img src={writinghand} style={{ width: "80%" }} />
//                   </div>
//                   <div style={{ marginLeft: "20px" }}>
//                     <img src={goreclasses} style={{ width: "80%" }} />
//                   </div>
//                   <div style={{ marginLeft: "20px" }}>
//                     <img src={teachlang} style={{ width: "90%" }} />
//                   </div>
//                   <div style={{ marginLeft: "20px" }}>
//                     <img src={deg} style={{ width: "80%" }} />
//                   </div>
//                   <div style={{ marginLeft: "20px" }}>
//                     <img src={briliant} style={{ width: "80%" }} />
//                   </div>
//                   <div style={{ marginLeft: "20px" }}>
//                     <img src={annanmuel} style={{ width: "80%" }} />
//                   </div>
//                   <div style={{ marginLeft: "20px", marginTop: "5px" }}>
//                     <img src={scc} style={{ width: "90%" }} />
//                   </div>
//                   <div style={{ marginLeft: "20px", marginTop: "5px" }}>
//                     <img src={flora} style={{ width: "90%" }} />
//                   </div>
//                 </div>
//               </div>
//             </Marquee>
//             </div>
// )
class Home extends React.Component {

  goToFeatures = (history) => {
    ServiceStore.getTopNavIndex(2);
    history.push('/features');
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }

  render() {
    const { history } = this.props;

    return (
      <div style={{ overflowX: 'hidden' }}>
        <Container>
          <Visible md lg xl xxl >
            <div className='' style={{ height: window.screen.height, }}>
              <Row1 image={image_11} image1={Mask_group} title={'An Online Ecosystem For All Educational Institutes BIG and SMALL'} image2={Path_52834} newTitle={discover} subtitle={discover1} />
              <div >
                <Button onClick={() => HomeStore.showRequestModal(true)} size={'large'} style={{ backgroundColor: "#594099", padding: '0px 15px', borderRadius: "6px" }}>
                  <span className='font-600' style={{ color: "#FFFFFF" }}>REQUEST DEMO</span>
                </Button>
                <img src={Path_617251} alt="" style={{ marginBottom: "55px", marginLeft: "-12px" }} />
              </div>
            </div>
          </Visible>
          <Hidden md lg xl xxl >
            <div className='' style={{ height: window.screen.height, }}>
              <Row2 image={image_11} image1={Mask_group} title={'An Online Ecosystem For All Educational Institutes BIG and SMALL'} image2={Path_52834} newTitle={discover} subtitle={discover1} />
              <div style={{ marginTop: "50px", textAlign: "center" }}>
                <Button onClick={() => HomeStore.showRequestModal(true)} size={'large'} style={{ backgroundColor: "#594099", padding: '0px 15px', borderRadius: "6px" }}>
                  <span className='font-600' style={{ color: "#FFFFFF" }}>REQUEST DEMO</span>
                </Button>
                <img src={Path_617251} alt="" style={{ marginBottom: "55px", marginLeft: "-12px" }} />
              </div>
            </div>
          </Hidden>
          <Visible md lg xl xxl >
            <div style={{ textAlign: "center" }}>
              <img src={flwer11} alt="" style={{ width: "13%", marginTop: "-150px" }} />
            </div>
            <Row4 image1={Mask_group} />
          </Visible>
          <Hidden md lg xl xxl>
            <div style={{ textAlign: "center" }}>
              <img src={flwer11} alt="" style={{ width: "40%", marginTop: "-100px" }} />
            </div>
            <Row8 image1={Mask_group} />
          </Hidden>
          
          <Visible md lg xl xxl xxxl>
            <div style={{ textAlign: "center" }}>
              <video width="70%" height="40%" loop={true} autoPlay={true} playsInline={true} muted={true}>
                <source src={homeVideo} type="video/mp4" />
              </video>
            </div>
          </Visible>
          

          <Visible md lg xl xxl>
            {/* <CustomSeamless />
             */}
             {/* <CustomFooter/> */}
             <div
              style={{
                display: "flex",
                justifyContent: "center",
                minHeight: 242,
                padding: '50px 5px',
                margin:" 0px auto "             
              }}
            >
             <CustomSeamless/>
            </div>

          </Visible>
          <Hidden md lg xl xxl>

            <div style={{
              marginTop: "50px", marginLeft: '-5%', width: "102.9%",
              overflowX: 'hidden',
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              backgroundColor: "#F6F4FE",
            }}>
              <center className='title-large-800 color-black m-l-10' style={{ fontSize: "1.1rem", marginTop: 40, marginBottom: 20, }}> A seamless mobile and web interface</center>

              <Marquee speed={48} gradient={false}>
                <div className="tech-slideshow" style={{ width: '110%', display: 'flex', }}>
                  <div style={{ marginRight: 20 }}>
                    <img src={s1} />
                  </div>
                  <div style={{ marginRight: 20 }}>
                    <img src={s2} />
                  </div>
                  <div style={{ marginRight: 20 }}>
                    <img src={s3} />
                  </div>

                  <div style={{ marginRight: 20 }}>
                    <img src={s4} />
                  </div>
                  <div style={{ marginRight: 20 }}>
                    <img src={s5} />
                  </div>
                  <div style={{ marginRight: 20 }}>
                    <img src={s6} />
                  </div>
                  <div style={{ marginRight: 20 }}>
                    <img src={s7} />
                  </div>
                  <div style={{ marginRight: 20 }}>
                    <img src={s8} />
                  </div>
                  <div style={{ marginRight: 20 }}>
                    <img src={s9} />
                  </div>
                  <div style={{ marginRight: 20 }}>
                    <img src={s10} />
                  </div>

                </div>
              </Marquee>

            </div>
            {/* <CustomSeamless /> */}

          </Hidden>
          <Visible md lg xl xxl >
            <div className='title-large-800 color-black' style={{ fontSize: "1.9rem", textAlign: "center", marginTop: "450px" }}>Build your own online brand with aulas</div>
          </Visible>
          <Hidden md lg xl xxl>
            <div className='title-large-800 color-black' style={{ fontSize: "1.2rem", marginTop: "50px" }}>Build your own online brand with aulas</div>
          </Hidden>

          <Visible md lg xl xxl >
            <Row3 image1={Vector_right} image={Group_595611} image2={flowerPot} image3={Group_5946} />
          </Visible>
          <Hidden md lg xl xxl>
            <Row10 image1={Vector_right} image={Group_595611} image2={flowerPot} image3={Group_5946} />
          </Hidden>
          <Visible md lg xl xxl >
            <div style={{ width: '100%', position: 'absolute', marginLeft: '-7%' }}>
              <Marquee speed={48} gradient={false}>
                <div style={{ width: '100%', position: 'relative', marginLeft: -30 }}>
                  <div style={{ display: "flex", marginTop: "40px", width: "115%" }}>
                    <div style={{ textAlign: "center", marginLeft: "50px" }}>
                      <img src={icuser1} alt="" style={{ width: "52%" }} />
                      <div style={{ textAlign: "center" }} className='subtitle-500 color-grey'>User Management</div>
                    </div>
                    <div style={{ textAlign: "center", marginLeft: "20px" }}>
                      <img src={ic_user1} alt="" style={{ width: "70%" }} />
                      <div style={{ textAlign: "center" }} className='subtitle-500 color-grey'>Courses</div>
                    </div>
                    <div style={{ textAlign: "center", marginLeft: "20px" }}>
                      <img src={ic_classrooms1} alt="" style={{ width: "70%" }} />
                      <div style={{ textAlign: "center" }} className='subtitle-500 color-grey'>Classroom</div>
                    </div>
                    <div style={{ textAlign: "center", marginLeft: "20px" }}>
                      <img src={ic_schedule} alt="" style={{ width: "70%" }} />
                      <div style={{ textAlign: "center" }} className='subtitle-500 color-grey'>Schedule</div>
                    </div>
                    <div style={{ textAlign: "center", marginLeft: "20px" }}>
                      <img src={ic_live_1} alt="" style={{ width: "70%" }} />
                      <div style={{ textAlign: "center" }} className='subtitle-500 color-grey'>Live Classes</div>
                    </div>
                    <div style={{ textAlign: "center", marginLeft: "20px" }}>
                      <img src={Group_15907} alt="" style={{ width: "70%" }} />
                      <div style={{ textAlign: "center" }} className='subtitle-500 color-grey'>Attendance</div>
                    </div>
                    <div style={{ textAlign: "center", marginLeft: "20px" }}>
                      <img src={Group_59113} alt="" style={{ width: "70%" }} />
                      <div style={{ textAlign: "center" }} className='subtitle-500 color-grey'>Digital Library</div>
                    </div>
                    <div style={{ textAlign: "center", marginLeft: "20px" }}>
                      <img src={ic_fee1} alt="" style={{ width: "55%" }} />
                      <div style={{ textAlign: "center" }} className='subtitle-500 color-grey'>Fee Management</div>
                    </div>
                    <div style={{ textAlign: "center", marginLeft: "20px" }}>
                      <img src={Group3} alt="" style={{ width: "75%" }} />
                      <div style={{ textAlign: "center" }} className='subtitle-500 color-grey'>Test</div>
                    </div>
                    <div style={{ textAlign: "center", marginLeft: "20px" }}>
                      <img src={Group_5912_1_1} alt="" style={{ width: "75%" }} />
                      <div style={{ textAlign: "center" }} className='subtitle-500 color-grey'>Practice Test</div>
                    </div>
                    <div style={{ textAlign: "center", marginLeft: "20px" }}>
                      <img src={Group2} alt="" style={{ width: "65%" }} />
                      <div style={{ textAlign: "center" }} className='subtitle-500 color-grey'>Announcement</div>
                    </div>
                    <div style={{ textAlign: "center", marginLeft: 20, marginTop: -3 }}>
                      <img src={Group1} alt="" style={{ width: "75%" }} />
                      <div style={{ textAlign: "center" }} className='subtitle-500 color-grey'>aulas care</div>
                    </div>
                  </div>
                </div>
              </Marquee>
            </div>
          </Visible>
          {/* <Visible md lg xl xxl>
          <div
              style={{
                display: "flex",
                justifyContent: "center",
                minHeight: 242,
                padding: '50px 5px',
                // backgroundColor: "#F8F8F8",
                margin:" 0px auto "             }}
            >
              <div style={{ width: '100%', position: 'absolute', }}>
              <Marquee speed={48} gradient={false}>
                <div style={{ width: '100%', position: 'relative',  }}>
                  <div style={{ display: "flex", marginTop: "40px", width: "115%" }}>
                    <div style={{ textAlign: "center", marginLeft: "50px" }}>
                      <img src={icuser1} alt="" style={{ width: "52%" }} />
                      <div style={{ textAlign: "center" }} className='subtitle-500 color-grey'>User management</div>
                    </div>
                    <div style={{ textAlign: "center", marginLeft: "20px" }}>
                      <img src={ic_user1} alt="" style={{ width: "70%" }} />
                      <div style={{ textAlign: "center" }} className='subtitle-500 color-grey'>Courses</div>
                    </div>
                    <div style={{ textAlign: "center", marginLeft: "20px" }}>
                      <img src={ic_classrooms1} alt="" style={{ width: "70%" }} />
                      <div style={{ textAlign: "center" }} className='subtitle-500 color-grey'>Classroom</div>
                    </div>
                    <div style={{ textAlign: "center", marginLeft: "20px" }}>
                      <img src={ic_schedule} alt="" style={{ width: "70%" }} />
                      <div style={{ textAlign: "center" }} className='subtitle-500 color-grey'>Schedule</div>
                    </div>
                    <div style={{ textAlign: "center", marginLeft: "20px" }}>
                      <img src={ic_live_1} alt="" style={{ width: "70%" }} />
                      <div style={{ textAlign: "center" }} className='subtitle-500 color-grey'>Live Classes</div>
                    </div>
                    <div style={{ textAlign: "center", marginLeft: "20px" }}>
                      <img src={Group_15907} alt="" style={{ width: "70%" }} />
                      <div style={{ textAlign: "center" }} className='subtitle-500 color-grey'>Attendance</div>
                    </div>
                    <div style={{ textAlign: "center", marginLeft: "20px" }}>
                      <img src={Group_59113} alt="" style={{ width: "70%" }} />
                      <div style={{ textAlign: "center" }} className='subtitle-500 color-grey'>Digital Library</div>
                    </div>
                    <div style={{ textAlign: "center", marginLeft: "20px" }}>
                      <img src={ic_fee1} alt="" style={{ width: "45%" }} />
                      <div style={{ textAlign: "center" }} className='subtitle-500 color-grey'>Fee Management</div>
                    </div>
                    <div style={{ textAlign: "center", marginLeft: "20px" }}>
                      <img src={Group3} alt="" style={{ width: "75%" }} />
                      <div style={{ textAlign: "center" }} className='subtitle-500 color-grey'>Test</div>
                    </div>
                    <div style={{ textAlign: "center", marginLeft: "20px" }}>
                      <img src={Group_5912_1_1} alt="" style={{ width: "75%" }} />
                      <div style={{ textAlign: "center" }} className='subtitle-500 color-grey'>Practice Test</div>
                    </div>
                    <div style={{ textAlign: "center", marginLeft: "20px" }}>
                      <img src={Group2} alt="" style={{ width: "65%" }} />
                      <div style={{ textAlign: "center" }} className='subtitle-500 color-grey'>Announcement</div>
                    </div>
                    <div style={{ textAlign: "center", marginLeft: 20, marginTop: -3 }}>
                      <img src={Group1} alt="" style={{ width: "75%" }} />
                      <div style={{ textAlign: "center" }} className='subtitle-500 color-grey'>Aulas Care</div>
                    </div>
                  </div>
                </div>
              </Marquee>
            </div>
              
              </div>
          </Visible> */}
          <Hidden sm md lg xl xxl xxxl>

            <Marquee speed={48} gradient={false}>

              <div style={{ width: '250%', }}>
                <div style={{ display: "flex", marginTop: "40px", width: "210%" }}>
                  <div style={{ textAlign: "center", marginLeft: "20px" }}>
                    <img src={icuser1} alt="" style={{ width: "60%" }} />
                    <div style={{ textAlign: "center" }} className='subtitle-500 color-grey'>User Management</div>
                  </div>
                  <div style={{ textAlign: "center", marginLeft: "20px" }}>
                    <img src={ic_user1} alt="" style={{ width: "80%" }} />
                    <div style={{ textAlign: "center" }} className='subtitle-500 color-grey'>Courses</div>
                  </div>
                  <div style={{ textAlign: "center", marginLeft: "20px" }}>
                    <img src={ic_classrooms1} alt="" style={{ width: "80%" }} />
                    <div style={{ textAlign: "center" }} className='subtitle-500 color-grey'>Classroom</div>
                  </div>
                  <div style={{ textAlign: "center", marginLeft: "20px" }}>
                    <img src={ic_schedule} alt="" style={{ width: "80%" }} />
                    <div style={{ textAlign: "center" }} className='subtitle-500 color-grey'>Schedule</div>
                  </div>
                  <div style={{ textAlign: "center", marginLeft: "20px" }}>
                    <img src={ic_live_1} alt="" style={{ width: "80%" }} />
                    <div style={{ textAlign: "center" }} className='subtitle-500 color-grey'>Live Classes</div>
                  </div>
                  <div style={{ textAlign: "center", marginLeft: "20px" }}>
                    <img src={Group_15907} alt="" style={{ width: "80%" }} />
                    <div style={{ textAlign: "center" }} className='subtitle-500 color-grey'>Attendance</div>
                  </div>
                  <div style={{ textAlign: "center", marginLeft: "20px" }}>
                    <img src={Group_59113} alt="" style={{ width: "80%" }} />
                    <div style={{ textAlign: "center" }} className='subtitle-500 color-grey'>Digital Library</div>
                  </div>
                  <div style={{ textAlign: "center", marginLeft: "20px" }}>
                    <img src={ic_fee1} alt="" style={{ width: "65%" }} />
                    <div style={{ textAlign: "center" }} className='subtitle-500 color-grey'>Fee Management</div>
                  </div>
                  <div style={{ textAlign: "center", marginLeft: "20px" }}>
                    <img src={Group3} alt="" style={{ width: "80%" }} />
                    <div style={{ textAlign: "center" }} className='subtitle-500 color-grey'>Test</div>
                  </div>
                  <div style={{ textAlign: "center", marginLeft: "20px" }}>
                    <img src={Group_5912_1_1} alt="" style={{ width: "80%" }} />
                    <div style={{ textAlign: "center" }} className='subtitle-500 color-grey'>Practice Test</div>
                  </div>
                  <div style={{ marginLeft: "20px" }}>
                    <img src={Group2} alt="" style={{ width: "70%", marginLeft: 10 }} />
                    <div style={{ textAlign: "center" }} className='subtitle-500 color-grey'>Announcement</div>
                  </div>
                  <div style={{ textAlign: "center", }}>
                    <img src={Group1} alt="" style={{ width: "80%" }} />
                    <div style={{ textAlign: "center" }} className='subtitle-500 color-grey'>aulas care</div>
                  </div>
                </div>
              </div>
            </Marquee>
          </Hidden>
          <Visible md lg xl xxl >
            <Row13 image1={Mask_group} title={'Embrace the Future of Education'} subtitle={embrace1} />
          </Visible>
          <Hidden md lg xl xxl>
            <Row14 image1={Mask_group} title={'Embrace the Future of Education'} subtitle={embrace1} />
          </Hidden>
          
{/* commented for time being (Testomonial section) */}

          {/* <CarouselData /> */}


          {/* <Visible md lg xl xxl >
          <div
              style={{
                display: "flex",
                justifyContent: "center",
                minHeight: 242,
                padding: '50px 5px',
                // backgroundColor: "#F8F8F8",
                margin:" 0px auto "             }}
            >
          <Row15/>
          </div>
          </Visible> */}


          {/* <Hidden md lg xl xxl>
            <Marquee speed={30} gradient={false}>

              <div style={{ width: '250%', marginTop: "10px" }}>
                <div style={{ display: "flex", width: "215%" }}>
                  <div style={{ marginLeft: "20px" }}>
                    <img src={rankerspoint} style={{ width: "80%" }} />
                  </div>
                  <div style={{ marginLeft: "20px" }}>
                    <img src={book1} style={{ width: "80%" }} />
                  </div>
                  <div style={{ marginLeft: "20px" }}>
                    <img src={writinghand} style={{ width: "80%" }} />
                  </div>
                  <div style={{ marginLeft: "20px" }}>
                    <img src={goreclasses} style={{ width: "80%" }} />
                  </div>
                  <div style={{ marginLeft: "20px" }}>
                    <img src={teachlang} style={{ width: "90%" }} />
                  </div>
                  <div style={{ marginLeft: "20px" }}>
                    <img src={deg} style={{ width: "80%" }} />
                  </div>
                  <div style={{ marginLeft: "20px" }}>
                    <img src={briliant} style={{ width: "80%" }} />
                  </div>
                  <div style={{ marginLeft: "20px" }}>
                    <img src={annanmuel} style={{ width: "80%" }} />
                  </div>
                  <div style={{ marginLeft: "20px", marginTop: "5px" }}>
                    <img src={scc} style={{ width: "90%" }} />
                  </div>
                  <div style={{ marginLeft: "20px", marginTop: "5px" }}>
                    <img src={flora} style={{ width: "90%" }} />
                  </div>

                </div>
              </div>
            </Marquee>

          </Hidden> */}

          <Visible md lg xl xxl>
            <div className='m-t-50 title-large-800 color-black' style={{ fontSize: "1.9rem", textAlign: "center", marginTop: "50px" }}>Our blogs</div>
            <Row>
              <Col xs={12} sm={6} md={4}>
                <Row6 image={bench_image} title={"Covid pandemic shows online learning is here for good: the mental barriers must go"} subtitle={onlineDef} value={"https://www.scmp.com/comment/letters/article/3115865/covid-pandemic-shows-online-learning-here-good-mental-barriers-must"} />
              </Col>
              <Col xs={12} sm={6} md={4}>
                <Row6 image={bench_image1} title={"How adapting e-education is now a necessity for th..."} subtitle={adaptingDef} value={"https://medium.com/@aulas_/how-adapting-e-education-is-now-a-necessity-for-the-teaching-industry-c2c2723cfe37"} />
              </Col>
              <Col xs={12} sm={6} md={4}>
                <Row6 image={bench_image2} title={"10 reasons why e-learning is more impactful on you..."} subtitle={reasonDef} value={"https://www.thestatesman.com/opinion/luxury-now-necessity-1502936601.html"} />
              </Col>
            </Row>
          </Visible>
          <Hidden md lg xl xxl>
            <div className='title-large-800 color-black' style={{ fontSize: "1.4rem", marginTop: "50px", textAlign: "center" }}>Our blogs</div>

            <Row11 />
          </Hidden>



          {/* <div style={{ textAlign: "center", marginTop: "40px" }}>
            <Button size={'large'} style={{ backgroundColor: "#594099", padding: '0px 15px', borderRadius: "6px", height: "55px" }}>
              <span className='font-600' style={{ color: "#FFFFFF" }}>VIEW MORE BLOGS</span>
            </Button>
          </div> */}
        </Container >
        <RequestModal />
      </div >
    )
  }
}

export default observer(Home);
